<template>
	<div>
		<b-card title="Manage Elements">
			<div class="custom-search d-flex justify-content-end">

				<div>
					<b-form-group>
						<div class="d-flex align-items-center">
						<label class="mr-1">Search</label>
						<b-form-input
							placeholder="Search"
							type="text"
							class="d-inline-block"
								@input="advanceSearch"
						/>
						</div>
					</b-form-group>
				</div>

				<div>
					<b-button
						class="ml-2"
						variant="gradient-primary float-right"
						size="md"
						v-b-modal.modal-add
					>
					Add Element
					</b-button>
				</div>
			</div>

			<vue-good-table
				:columns="columns"
				:rows="rows"
				:rtl="direction"
				:search-options="{
					enabled: true,
					externalQuery: searchTerm }"
				:pagination-options="{
					enabled: true,
					perPage:pageLength
				}"
				theme="my-theme"
				@on-row-click="onRowClick"
			>
				<template
					slot="table-row"
					slot-scope="props"
				>
					<div
						v-if="props.column.field === 'action'"
						class="text-nowrap"
					>
						<b-button-group v-if="props.row.id != 'admin1'">
							<b-button
								variant="gradient-danger"
								size="sm"
								@click="onActionDelete(props.row.ae_id)"
							>
								<trash-icon size="1x" />
							</b-button>
							<b-button
								variant="gradient-primary"
								size="sm"
								@click="onActionEdit(props.row.ae_id)"
							>
								<edit-2-icon size="1x" />
							</b-button>
						</b-button-group>
					</div>
				</template> 

				<!-- pagination -->
				<template
					slot="pagination-bottom"
					slot-scope="props"
				>
					<div class="d-flex justify-content-between flex-wrap">
					<div class="d-flex align-items-center mb-0 mt-1">
						<span class="text-nowrap">
						Showing 1 to
						</span>
						<b-form-select
						v-model="pageLength"
						:options="['5','10','15', '20']"
						class="mx-1"
						@input="(value)=>props.perPageChanged({currentPerPage:value})"
						/>
						<span class="text-nowrap"> of {{ props.total }} entries </span>
					</div>
					<div>
						<b-pagination
							:value="1"
							:total-rows="props.total"
							:per-page="pageLength"
							first-number
							last-number
							align="right"
							prev-class="prev-item"
							next-class="next-item"
							class="mt-1 mb-0"
							@input="(value)=>props.pageChanged({currentPage:value})"
						>
							<template #prev-text>
								<feather-icon
								icon="ChevronLeftIcon"
								size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
								icon="ChevronRightIcon"
								size="18"
								/>
							</template>
						</b-pagination>
					</div>
					</div>
				</template>
			</vue-good-table>
		</b-card>
	</div>
</template>


<script>

import {
  BCard, BFormGroup, BFormInput, BButton, BButtonGroup, BPagination, BFormSelect, BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import metadata from "@/api/metadata";
import store from '@/store/index'
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
export default {
  	components: {
		BCard,
		ToastificationContent,
		VueGoodTable,
		BFormGroup, 
		BFormInput, 
		BButton, 
		BButtonGroup, 
		BPagination, 
		BFormSelect,
		BOverlay,
		TrashIcon,
		Edit2Icon,
	},
	data() {
		return {
			pageLength: 10,
			dir: false,
			columns: [
				{
					label: 'ID',
					field: 'ae_id',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Type',
					field: 'ae_form_type',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Parameter Name',
					field: 'ae_label',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				
				{
					label: 'Parameter Name (DB)',
					field: 'ae_attribut',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Action',
					field: 'action',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
			],
			rows: [],
			searchTerm: '',
			showLoading: false,
		}
	},

	computed: {
		direction() {
			if (store.state.appConfig.isRTL) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.dir = true
				return this.dir
			}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.dir = false
			return this.dir
		},
	},

	mounted() {
		this.getListElements();
	},

	methods: {
		async getListElements() {      
			const { data } = await metadata.getStationElement();
			
			this.rows = data.sort(function(a, b){
					return parseInt(a.ae_id) - parseInt(b.ae_id)
				})

			
		},

		advanceSearch(val) {
			this.searchTerm = val
		},

		onRowClick(params) {
			
		},
	}
	
};
</script>


<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
input.form-control {
    border: 1px solid #6e6b7b !important;
}

.vgt-table thead th.theadc {
	background-color: #f7fafc !important;
}
</style>